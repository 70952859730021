import React, { useRef, useEffect, useState } from "react"; // Add useState
import { Link } from "react-router-dom";
import Logo from "./assets/logo.svg";
import "./CommingSoon.css";
import ArrowTopRight from "./assets/arrow-top-right.svg";
import ArrowTopRightGrey from "./assets/arrow-top-right_grey.svg";
import VideoThumbnail from "../VideoThumbnail/VideoThumbnail";
import HoverImg from "./assets/hover.svg";
import { CustomButton1, CustomButton2 } from "../Buttons/Buttons";
const ComingSoon = () => {
  const containerRef = useRef(null);
  const hoverImgRef = useRef(null);
  const footerRef = useRef(null);
  const [videoState, setVideoState] = useState(false)
  const [clickedLink, setClickedLink] = useState(null);

  const handleLinkClick = (linkName) => {
    setClickedLink(linkName);
  };

  useEffect(() => {
    const setMinHeight = () => {
      const vh = window.innerHeight;
      if (containerRef.current) {
        containerRef.current.style.minHeight = `${vh}px`;
      }
    };

    const handleMouseMove = (event) => {
      if (hoverImgRef.current  && window.innerWidth > 768) {
        const { clientX, clientY } = event;
        const imageWidth = hoverImgRef.current.offsetWidth;
        const imageHeight = hoverImgRef.current.offsetHeight;
        
        const x = clientX - imageWidth / 2;
        const y = clientY - imageHeight / 2;
      
        
        hoverImgRef.current.style.transform = `translate(${x}px, ${y}px)`;
      }
    };

    setMinHeight();
    window.addEventListener("resize", setMinHeight);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("resize", setMinHeight);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(()=>{
    if(footerRef.current){
      if(videoState){
        footerRef.current.style.zIndex = '1';
      }
      else {
          footerRef.current.style.zIndex = '3';
      }
    }
  }, [videoState])
  return (
    <div className="coming-soon" ref={containerRef}>
      <img
        ref={hoverImgRef}
        src={HoverImg}
        id="HoverImage"
        width={1000}
        height={1000}
      ></img>
      <section className="logo">
        <img width={158} src={Logo} alt="Devalok" />
      </section>
      <section className="container-outer">
        <div className="rotating-bg"></div> {/* Rotating background layer */}
        <section className="container">
          <h2>
            Good things take time, and <br />
            good <VideoThumbnail videoState={videoState} setVideoState={setVideoState}/> <i>design</i> takes longer.
          </h2>
          <p
            style={{
              opacity: 0.5,
            }}
          >
            We'll be live soon!
          </p>
          <div className="buttons">
            <Link to="https://cal.com/muditlal/15min" rel="noopener noreferrer">
              <CustomButton2 text={"Book A Call"} />
            </Link>
            <Link
              to="https://www.behance.net/devalok"
              rel="noopener noreferrer"
            >
              <CustomButton1 text={"View Portfolio"} />
            </Link>
          </div>
        </section>
      </section>
      <section className="footer" ref={footerRef}>
        <Link
          to="https://www.instagram.com/devalok.studio/"
          rel="noopener noreferrer"
          onClick={() => handleLinkClick("instagram")}
          className={clickedLink === "instagram" ? "clicked" : ""}
        >
          Instagram{" "}
          <img width={32} height={32} src={clickedLink === "instagram" ? ArrowTopRight : ArrowTopRightGrey} alt="arrow" />
        </Link>
        <Link
          to="https://join.devalok.in"
          rel="noopener noreferrer"
          onClick={() => handleLinkClick("join")}
          className={clickedLink === "join" ? "clicked" : ""}
        >
          Join The Lok{" "}
          <img width={32} height={32} src={clickedLink === "join" ? ArrowTopRight : ArrowTopRightGrey} alt="arrow" />
        </Link>
      </section>
    </div>
  );
};

export default ComingSoon;
