import React from "react";
import "./Buttons.css";

const CustomButton1 = ({
  text,
  className = "",
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <button
      className={`custom-button-1 ${className}`.trim()}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const CustomButton2 = ({
  text,
  className = "",
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <button
      className={`custom-button-2 ${className}`.trim()}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export { CustomButton1, CustomButton2 };