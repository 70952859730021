import React, { useState, useRef, useEffect } from "react";
import "./VideoThumbnail.css";

const VideoThumbnail = ({videoState, setVideoState}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const thumbnailRef = useRef(null);
  const fullscreenRef = useRef(null);
  const containerRef = useRef(null);
  
  const videoSource =
    "https://wlbs-crm.s3.ap-south-1.amazonaws.com/devalok/video.mp4";

  const handleMouseMove = (e) => {
    if (!containerRef.current) return;

    if (window.innerWidth > 768) {
      const rect = containerRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      // Calculate distance from center
      const deltaX = e.clientX - centerX;
      const deltaY = e.clientY - centerY;

      // Maximum movement in pixels
      const maxMove = 10;

      // Calculate movement with a dampening factor
      const moveX = (deltaX / window.innerWidth) * maxMove;
      const moveY = (deltaY / window.innerHeight) * maxMove;

      setPosition({ x: moveX, y: moveY });
    }
  };

  const handleMouseLeave = () => {
    // Reset position when mouse leaves
    setPosition({ x: 0, y: 0 });
  };

  const handleShowreel = () => {
    setIsPlaying(true);
    setVideoState(true)
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setIsPlaying(false);
    setVideoState(false)
    if (fullscreenRef.current) {
      fullscreenRef.current.pause();
    }
    if (thumbnailRef.current) {
      thumbnailRef.current.currentTime = 0;
    }
  };

  const handleVideoClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (thumbnailRef.current) {
      thumbnailRef.current.play();
    }

    // Add mouse move listener to window
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <span>
      <div
        ref={containerRef}
        onClick={handleShowreel}
        className="video-thumbnail"
        onMouseLeave={handleMouseLeave}
        style={{
          flexShrink: 0,
          position: "relative",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          borderRadius: "45px",
          overflow: "hidden",
          verticalAlign: "middle",
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      >
        <video
          ref={thumbnailRef}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
          muted
          playsInline
          loop
          src={videoSource}
        />

        <div
          className="play-button"
          style={{
            width: "24px",
            height: "24px",
            position: "relative",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="40"
            viewBox="0 0 37 40"
            fill="none"
            style={{
              scale: "1.5",
            }}
          >
            <g filter="url(#filter0_bd_168_27)">
              <path
                d="M26.5 20C26.5006 20.2546 26.4353 20.5051 26.3105 20.7271C26.1856 20.949 26.0055 21.1349 25.7875 21.2666L12.28 29.5297C12.0523 29.6691 11.7914 29.7453 11.5244 29.7502C11.2575 29.7551 10.994 29.6887 10.7613 29.5578C10.5307 29.4289 10.3387 29.241 10.2049 29.0132C10.0711 28.7855 10.0004 28.5263 10 28.2622V11.7378C10.0004 11.4737 10.0711 11.2145 10.2049 10.9867C10.3387 10.759 10.5307 10.5711 10.7613 10.4422C10.994 10.3113 11.2575 10.2448 11.5244 10.2498C11.7914 10.2547 12.0523 10.3309 12.28 10.4703L25.7875 18.7334C26.0055 18.8651 26.1856 19.051 26.3105 19.2729C26.4353 19.4949 26.5006 19.7453 26.5 20Z"
                fill="white"
                fill-opacity="0.5"
                shape-rendering="crispEdges"
              />
              <path
                d="M25.6582 21.0526L25.657 21.0533L12.1495 29.3164L12.1494 29.3165C11.9597 29.4327 11.7423 29.4961 11.5198 29.5002C11.2973 29.5044 11.0778 29.449 10.8838 29.3399L10.8833 29.3396C10.6915 29.2324 10.5317 29.076 10.4205 28.8866C10.3092 28.6972 10.2504 28.4816 10.25 28.262V11.738C10.2504 11.5184 10.3092 11.3028 10.4205 11.1134C10.5317 10.924 10.6915 10.7676 10.8833 10.6604L10.8833 10.6604L10.8838 10.6601C11.0778 10.551 11.2973 10.4956 11.5198 10.4997C11.7423 10.5039 11.9597 10.5673 12.1494 10.6835L12.1495 10.6836L25.657 18.9467L25.6582 18.9474C25.8392 19.0568 25.9889 19.2112 26.0926 19.3955C26.1963 19.5798 26.2505 19.7879 26.25 19.9994L26.25 20.0006C26.2505 20.2121 26.1963 20.4201 26.0926 20.6045C25.9889 20.7888 25.8392 20.9432 25.6582 21.0526Z"
                stroke="white"
                stroke-width="0.5"
                shape-rendering="crispEdges"
              />
            </g>
            <defs>
              <filter
                id="filter0_bd_168_27"
                x="0"
                y="0.249527"
                width="36.5"
                height="39.5009"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_168_27"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="15" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_backgroundBlur_168_27"
                  result="effect2_dropShadow_168_27"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect2_dropShadow_168_27"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>

      {isPlaying && (
        <div
          className="video-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleClose}
        >
          <video
            ref={fullscreenRef}
            autoPlay
            controls
            className="fullscreen-video"
            style={{
              width: "100%",
              height: "100%",
            }}
            onClick={handleVideoClick}
            onEnded={handleClose}
            src={videoSource}
          />
          <button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              border: "none",
              color: "white",
              padding: "10px 15px",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Close
          </button>
        </div>
      )}
    </span>
  );
};

export default VideoThumbnail;
